<template>
  <div class="obody">
    <loginDialog ref="dialog"/>
    <HomePageTop></HomePageTop>
    <!-- 步骤显示 -->
    <div class="stepGroup">
      <div class="step">
        <div :class="{ isStep: step == 1}" class="item" style="margin-right: 5px">
          <div class="flexAndCenter"><span> 1 </span>
            <div>{{ $fanyi("填写个人信息") }}</div>
          </div>
          <div :class="{ isStepBorder: step == 1 }" class="newIsStepBorder"></div>
        </div>
        <div :class="{ isStep: step == 2}" class="item" style="margin-right: 5px">
          <div class="flexAndCenter"><span>2</span>
            <div>{{ $fanyi("确认个人信息") }}</div>
          </div>
          <div :class="{ isStepBorder: step == 2 }" class="newIsStepBorder"></div>
        </div>
        <div :class="{ isStep: step == 3}" class="item">
          <div class="flexAndCenter"><span>3</span>
            <div>{{ $fanyi("注册完成") }}</div>
          </div>
          <div :class="{ isStepBorder: step == 3 }" class="newIsStepBorder"></div>
        </div>
      </div>
    </div>
    <div class="logo" style="background: #fff">
      <img alt="" src="../../assets/1688/commoditySearchPageLogo.png">
    </div>
    <div class="registration_complete">
      <div class="registration_completeCon">
        <div class="registration_completeConOpt" style="margin-bottom: 0">
          <img class="successImg" src="../../assets/icon-1.png"/>
        </div>
        <div class="newRegistration_completeConOpt" style="margin: 26px 0 60px 0">
          <span>{{ $fanyi("注册完了") }}</span>
          <span>{{ $fanyi("非常感谢您的注册") }}</span>
        </div>
        <div class="accountSynchronizationContainer">
          <div class="headerText">
            RAKUMARをご利用いただきありがとうございます。この度弊社と中国アリババグループの提携により、弊社での登録携帯番号で１６８８，タオバオのアカウント新規登録できるサービスを提供しており、必要の場合は連結ボタンをクリックして進めてことが可能です。
            よろしくお願いします。
          </div>
          <div class="item">
            <img alt="" src="../../assets/userDetail/1688.png">
            <div>https://www.1688.com/</div>
          </div>
          <div class="item">
            <img alt="" src="../../assets/userDetail/taobao.png">
            <div>https://www.taobao.com/</div>
          </div>
          <div class="item">
            <img alt="" src="../../assets/userDetail/tmall.png">
            <div>https://www.tmall.com/</div>
          </div>
          <div class="footerTextContainer">
            <div>アカウント連結について</div>
            <div @click="pageJump">詳細へ</div>
          </div>
          <div v-if="!isRegister" class="synchronizationBtn" @click="showAssociationDialogVisible">今すぐ連結へ</div>
        </div>
        <div class="flexAndCenter twoStepButtonList" style="margin-top: 0">
          <div class="goBackDiv" @click.prevent="$router.replace('/')">{{ $fanyi('返回首页') }}</div>
          <el-button type="primary" @click.prevent="$router.replace('/login')">{{ $fanyi('立即登录') }}</el-button>
        </div>
      </div>
    </div>
    <div class="fooot">
      <Foot></Foot>
    </div>
    <div class="newDialogContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
          :visible.sync="associationDialogVisible"
          width="580px"
      >
        <associationDialog @close="associationDialogVisible=false;"/>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
import Foot from "../../components/foot/Foot.vue";
import loginDialog from "@/components/public/loginDialog.vue";
import associationDialog from "@/views/UserCenter/views/userDetail/components/accountAssociationDialog.vue";

export default {
  components: {
    associationDialog,
    loginDialog,
    HomePageTop,
    Foot
  },
  data() {
    return {
      step: 3,
      associationDialogVisible: false,
      isRegister: false,
    }
  },
  created() {
    this.$api.alibabaUserRegisterList().then((res) => {
      this.isRegister = res.data.length > 0
    });
  },
  methods: {
    showAssociationDialogVisible() {
      let user_token = localStorage.getItem('japan_user_token');
      if (user_token != null) {
        this.associationDialogVisible = true;
      } else {
        this.$refs.dialog.visible = true;
      }
    },
    pageJump() {
      window.open('/accountSynchronization')
    }
  }
}
</script>

<style lang="scss" scoped>
.stepGroup {
  width: 1400px;
  padding: 34px 0 36px;
  margin: 20px auto 0;
  background-color: #fff;
  border-radius: 6px 6px 0 0;

  .step {
    width: 1000px;
    display: flex;
    justify-content: center;
    margin: 0 auto;

    .item {
      color: #999;
      text-align: center;

      span {
        display: inline-block;
        width: 25px;
        height: 25px;
        background-color: #DFDFDF;
        line-height: 25px;
        color: white;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .newIsStepBorder {
      width: 330px;
      height: 6px;
      background: #DFDFDF;
      border-radius: 3px;
      margin-top: 6px;
    }

    .isStepBorder {
      width: 330px;
      height: 6px;
      border-radius: 3px;
      margin-top: 6px;
      background: #B4272B;
    }

    .isStep {
      color: #B4272B;

      span {
        background-color: #B4272B;
        color: #fff;
      }
    }

    .successStep {
      span {
        background-color: #47bd9b;
        outline-color: #c0f1e3;
        color: #fff;
      }
    }

    .border {
      flex: 1;
      height: 1px;
      background-color: #d7d7d7;
      margin: 14px 28px 0;
    }
  }
}

.logo {
  display: flex;
  justify-content: center;
  padding-bottom: 36px;
  width: 1400px;
  margin: 0 auto;
}

.registration_complete {
  width: 1400px;
  margin: 0 auto;
  text-align: center;
  padding: 0 80px 60px;
  background-color: white;
  border-radius: 0 0 6px 6px;

  > .registration_completeCon {
    margin: 0 auto;
    width: 1000px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d7d7d7;

    .registration_completeConOpt {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      padding-top: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .successImg {
        width: 80px;
        height: 80px
      }

      span {
        font-size: 14px;

        line-height: 20px;
      }

      button {
        min-width: 195px;
        background: #1e2997;
        border-radius: 6px;
        padding: 0 40px;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        height: 54px;
      }
    }

    .newRegistration_completeConOpt {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 18px;
        color: #333;
      }
    }

    .accountSynchronizationContainer {
      width: 640px;
      border-radius: 3px;
      background: #F6F6F6;
      padding: 43px 0 40px 110px;
      margin-bottom: 40px;
      margin-top: -34px;

      .headerText {
        color: #333333;
        font-size: 14px;
        text-align: left;
        margin-bottom: 10px;
      }

      .item {
        display: flex;
        align-items: center;
        height: 34px;
        margin-bottom: 10px;

        img {
          width: 32px;
          height: 32px;
          margin-right: 16px;
        }

        div {
          color: #000000;
          font-size: 14px;
        }
      }

      .footerTextContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 20px;

        div:first-child {
          color: #999999;
          margin-right: 4px;
        }

        div:last-child {
          color: #333333;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .synchronizationBtn {
        color: #B4272B;
        text-decoration: underline;
        font-weight: bold;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
      }
    }
  }
}

.twoStepButtonList {
  margin-top: 37px;

  /deep/ .el-button {
    width: 150px;
    height: 38px;
    background: #B4272B;
    border-radius: 3px;
    text-align: center;
    border: none;
    padding: 0;
  }

  .goBackDiv {
    width: 150px;
    height: 38px;
    background: transparent;
    border-radius: 3px;
    text-align: center;
    border: 1px solid #B4272B;
    padding: 0;
    line-height: 38px;
    font-size: 14px;
    color: #B4272B;
    margin-right: 20px;
    cursor: pointer;
  }

  .el-button + .el-button {
    margin-left: 20px;
  }
}

.newDialogContainer /deep/ .el-dialog {
  border-radius: 10px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header, .el-dialog__body {
    padding: 0;
  }

  .dialogCenter {
    padding: 40px 0;

    div {
      color: #333333;
      font-weight: bold;
      text-align: center;
      font-size: 16px;
    }
  }

  .dialogFooter {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    div {
      width: 120px;
      height: 34px;
      background: #B4272B;
      border-radius: 8px;
      line-height: 34px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.fooot {
  margin-top: 30px;
}
</style>